import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-leave-confirmation',
  templateUrl: './leave-confirmation.component.html',
  styleUrls: ['./leave-confirmation.component.scss']
})
export class LeaveConfirmationComponent implements OnInit {
  @Input() startDate:string
  @Input() endDate:string
  @Input() fromTime:string
  @Input() toTime:string
  @Input() leaveType:string
  @Input() departmentType:string
  @Input() noOfDays:string
  @Input() isDayWise:boolean
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  closeModal(isValid:boolean = false){
    if (isValid) return this.activeModal.close(true)
    return this.activeModal.dismiss()
  }

}
